import React from 'react';
import { Button } from 'digital-rabbit-cl';
import styled from 'styled-components';

import demoVidSm from '../videos/covid-app-demo-sm.mp4';
import demoVidLg from '../videos/covid-app-demo-lg.mp4';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import ResponsiveSection from '../components/ResponsiveSection';
import theme from '../theme';

const PortfolioDiv = styled.div`
  display: grid;
  grid-gap: ${theme.layout.wrapperPadding};

  & > div:first-child {
    grid-row: 2;
  }

  @media screen and (min-width: ${theme.breakpoints.md}) {
    align-items: center;
    grid-template-columns: 40% 1fr;
    grid-gap: calc(${theme.layout.wrapperPadding} * 2);

    & > div:first-child {
      grid-row: 1;
    }
  }
`;

const PageTitle = styled.h1`
  margin-bottom: -${theme.fonts.rhythm};
  margin-top: calc(${theme.fonts.rhythm} * 2);
  text-align: center;

  @media screen and (min-width: ${theme.breakpoints.md}) {
    margin-bottom: 0;
    margin-top: ${theme.fonts.rhythm};
  }
`;

const PortfolioPage = () => (
  <Layout pagePadding={true}>
    <SEO title="Portfolio" />
    <PageTitle>Portfolio</PageTitle>
    <ResponsiveSection>
      <PortfolioDiv>
        <div>
          <h3>COVID-19 Tracker</h3>
          <p>The Digital Rabbit COVID-19 Tracker monitors COVID-19 related data at the global level. Users can select a parameter and visualize the data set as a ranked list and a chloropleth world map.</p>
          <Button
            color={theme.colors.accent}
            onClick={() => {
              window.open(
                'https://covid19-tracker.digitalrabb.it',
                '_blank'
              );
            }}
          >
            View Site →
          </Button>
        </div>
        {/* 16:9 aspect ratio */}
        <video
          style={{
            borderRadius: '6px',
            outline: 'none',
            width: '100%',
          }}
          autoPlay
          muted
          loop
        >
          <source src={demoVidSm} type="video/mp4" />
          <source src={demoVidLg} type="video/mp4" media="all and (min-device-width: 481px)" />
          Your browser does not support the video tag.
        </video>
      </PortfolioDiv>
    </ResponsiveSection>
  </Layout>
);

export default PortfolioPage;
